import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ClientLogo } from '../../Assets/header_logo.svg';
import { ReactComponent as ClientLogoMoment } from '../../Assets/moments_logo.svg';
import { ReactComponent as UnityLogo } from '../../Assets/Icons/UnityLogoWhite.svg';
import { NYSELogoSmall } from '../Util/NYSELogo';
import { Box } from '@mui/material';
import { headerStyles } from './Header.style';

const HeaderWrapper = () => (
    <Box sx={headerStyles.header}>
        <UnityNYSELogos />
        <Box component={ClientLogo} sx={{ flexShrink: 0 }} />
        <LegalInfo />
    </Box>
);

export const Footer = () => (
    <Box sx={headerStyles.header}>
        <UnityNYSELogos />
        <LegalInfo />
    </Box>
);

export const SmallerHeader = () => (
    <Box sx={headerStyles.mobileHeader}>
        <Box component={ClientLogo} sx={{ flexShrink: 0 }} />
    </Box>
);

export const NarrowFooter = () => {
    const { t } = useTranslation();

    return (
        <Box sx={headerStyles.headerCenter}>
            <UnityNYSELogos />
            <Box sx={headerStyles.legalCenter}>
                <Box
                    component='a'
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://unity3d.com/legal'
                    sx={headerStyles.link}>
                    {t('header.legal_label')}
                </Box>
                <Box
                    component='a'
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://unity3d.com/legal/privacy-policy'
                    sx={headerStyles.link}>
                    {t('header.privacy_policy_label')}
                </Box>
            </Box>
            <Box component='span' sx={headerStyles.copyrightNarrow}>
                {t('header.copyright_label')}
            </Box>
        </Box>
    );
};

export const UnityNYSELogos = () => (
    <Box sx={headerStyles.headerLeft}>
        <NYSELogoSmall />
        <Box sx={headerStyles.NYSEVerticalBar} />
        <UnityLogo width='66' height='22' fill='#0A0B09' />
    </Box>
);

export const LegalInfo = () => {
    const { t } = useTranslation();

    return (
        <Box sx={headerStyles.headerRight}>
            <Box sx={headerStyles.legal}>
                <Box
                    component='a'
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://unity3d.com/legal'
                    sx={headerStyles.link}>
                    {t('header.legal_label')}
                </Box>
                <Box
                    component='a'
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://unity3d.com/legal/privacy-policy'
                    sx={headerStyles.link}>
                    {t('header.privacy_policy_label')}
                </Box>
            </Box>
            <Box sx={headerStyles.copyright}>{t('header.copyright_label')}</Box>
        </Box>
    );
};

export const MomentsHeader = () => {
    const { t } = useTranslation();

    return (
        <Box sx={headerStyles.headerMiddle}>
            <Box component={ClientLogoMoment} sx={headerStyles.clientLogo} />
            <Box component='span' sx={headerStyles.momentsVerticalBar} />
            <Box sx={headerStyles.listingDay}>{t('header.listing_day_label')}</Box>
        </Box>
    );
};

export default HeaderWrapper;
