export const loginConfirmationStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: '24px',
        background: theme => theme.palette.loginGradient.main,
        height: '100%',
        width: '100%'
    },
    logoutSuccessIcon: {
        width: 84,
        height: 86
    },
    title: {
        color: 'white',
        fontFamily: 'InterBold',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '38px',
        lineHeight: '44px'
    },
    body: {
        color: 'white',
        fontFamily: 'InterRegular',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '26px'
    },
    logoutButton: {
        color: 'white',
        textTransform: 'none',
        fontFamily: 'InterRegular',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px'
    }
};
