import { useOktaAuth } from '@okta/okta-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { loginCallbackWithErrorStyles } from './LoginCallbackWithError.style';
import ReplayIcon from '@mui/icons-material/Replay';
import ErrorIcon from '@mui/icons-material/Error';

let hasTriggeredCallback = false;

export const LoginCallbackWithError = () => {
    const { oktaAuth } = useOktaAuth();
    const { t } = useTranslation();
    const [error, setError] = useState(null);
    const [isLoading, setisLoading] = useState(false);

    const handleLogout = () => {
        setisLoading(true);
        try {
            oktaAuth.signOut('/');
        } catch {
            setisLoading(false);
        }
    };

    const handleRedirect = useCallback(async () => {
        if (error) return;
        try {
            if (hasTriggeredCallback) return;
            hasTriggeredCallback = true;
            await oktaAuth.handleLoginRedirect();
        } catch (loginRedirectError) {
            setError(loginRedirectError.errorSummary);
        }
    }, [error, oktaAuth]);

    useEffect(() => {
        handleRedirect();
    }, [handleRedirect]);

    if (!error) return null;

    return (
        <Box sx={loginCallbackWithErrorStyles.container}>
            <Typography variant='h4' color='white' fontWeight='700' mb='24px'>
                There was an issue with your login attempt.
            </Typography>
            <Box sx={loginCallbackWithErrorStyles.errorMessage}>
                <ErrorIcon sx={{ mr: 1 }} />
                <Typography>{error}</Typography>
            </Box>
            <Button
                variant='contained'
                color='primary'
                size='large'
                onClick={handleLogout}
                startIcon={!isLoading && <ReplayIcon />}
                sx={loginCallbackWithErrorStyles.retryButton}>
                {isLoading ? <CircularProgress size={30} sx={{ color: 'white' }} /> : t('login.retry_button')}
            </Button>
        </Box>
    );
};
