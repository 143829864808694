import React, { useEffect, useState } from 'react';
import { useDetectLogout } from '../../../Hooks/useDetectLogout';
import LoginSuccess from '../../../Assets/login_success.gif';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Button, CircularProgress } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { loginConfirmationStyle } from './LoginConfirmation.style';
import { StorageKeys } from '../../../constants';

export const LoginConfirmation = () => {
    const { loggingOut } = useDetectLogout('/');
    const { oktaAuth } = useOktaAuth();
    const [isLoading, setisLoading] = useState(false);

    const handleLogout = () => {
        setisLoading(true);
        try {
            oktaAuth.signOut('/');
        } catch {
            setisLoading(false);
        }
    };

    const ipoDate = localStorage.getItem(StorageKeys.ipoDate);
    const millisecondsToEvent = Date.parse(ipoDate) - Date.now();
    const daysToEvent = Math.floor(millisecondsToEvent / 8.64e7);

    useEffect(() => {
        window.history.replaceState({}, document.title, '/');
    }, []);

    if (loggingOut) {
        return null;
    }

    return (
        <Box sx={loginConfirmationStyle.container}>
            <Box component='img' sx={loginConfirmationStyle.logoutSuccessIcon} src={LoginSuccess} alt={'success'} />
            <Box sx={loginConfirmationStyle.title}>You are all set!</Box>
            <Box sx={loginConfirmationStyle.body}>
                We will see you again in
                {daysToEvent >= 1 ? <b> [{daysToEvent + 1} days]</b> : ' soon'}. You can exit this page now.
            </Box>
            <Button
                variant='contained'
                color='primary'
                onClick={handleLogout}
                sx={loginConfirmationStyle.logoutButton}
                startIcon={!isLoading && <LogoutIcon />}>
                {isLoading ? <CircularProgress size={30} sx={{ color: 'white' }} /> : 'Log Out'}
            </Button>
        </Box>
    );
};
