import { useOktaAuth } from '@okta/okta-react';
import { StorageKeys } from '../constants';
import { OKTA_ISSUER } from '../environment';

export const useDetectLogout = () => {
    const { authState, oktaAuth } = useOktaAuth();

    if (!authState.accessToken) {
        console.warn('no accessToken - redirecting to Okta');
        oktaAuth.signOut('/');
        return { loggingOut: true };
    }

    if (authState.accessToken) {
        const { exp, aud, iss, cid } = authState.accessToken.claims;
        const nowUTC = Math.round(new Date().getTime() / 1000);

        const timeDiffMinutes = (exp - nowUTC) / 60;

        // if token is about to expire in 5 hours - renew it already
        if (timeDiffMinutes < 300) {
            console.warn('accessToken about to expire - redirecting to Okta');
            oktaAuth.signOut('/');
            return { loggingOut: true };
        }

        if (aud !== 'nyse') {
            console.warn('accessToken from another audience - redirecting to Okta');
            oktaAuth.signOut('/');
            return { loggingOut: true };
        }

        if (OKTA_ISSUER !== iss) {
            console.warn('accessToken from another issuer - redirecting to Okta');
            oktaAuth.signOut('/');
            return { loggingOut: true };
        }

        if (localStorage.getItem(StorageKeys.clientId) !== cid) {
            console.warn('accessToken from another client id - redirecting to Okta');
            oktaAuth.signOut('/');
            return { loggingOut: true };
        }
    }

    return { loggingOut: false };
};
