import React, { useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Box, Button, Card, Typography } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import Bowser from 'bowser';
import { useTranslation } from 'react-i18next';
import { oktaConfig } from '../../../oktaConfig';
import { NarrowFooter } from '../../Header/Header';
import { loginStyles } from './Login.styles';
import { StorageKeys } from '../../../constants';

export const LoginPage = () => {
    const { oktaAuth, authState } = useOktaAuth();
    const history = useHistory();
    const { t } = useTranslation();
    const clientName = localStorage.getItem(StorageKeys.clientName);

    const isUnsupportedSafari = useMemo(() => {
        const browser = Bowser.getParser(window.navigator.userAgent);
        return browser.satisfies({ safari: '<15' });
    }, []);

    if (authState && authState.isAuthenticated) {
        history.push('/');
        return null;
    }

    if (!oktaConfig.clientId) {
        return (
            <Box sx={{ color: 'white', textAlign: 'center', p: 20 }}>
                <Typography variant='h4' color='white' fontWeight='700' mb='24px'>
                    Client info missing
                </Typography>
                <Typography>
                    There was an issue with your login URL. Please close this page and click on the correct link
                    provided to you by our team.
                </Typography>
            </Box>
        );
    }

    if (!oktaConfig.idp && !oktaConfig.multipleIDP) {
        oktaAuth.signInWithRedirect();
        return null;
    }

    const loginWithIDP = idp => async () => {
        try {
            await oktaAuth.token.getWithRedirect({
                idp
            });
        } catch (error) {
            console.log(error);
        }
    };

    const loginWithCustomEmail = async () => {
        try {
            await oktaAuth.signInWithRedirect();
        } catch (error) {
            console.log(error);
        }
    };

    const oktaIDPs = [];
    if (oktaConfig.multipleIDP) {
        Object.keys(oktaConfig.multipleIDP).forEach(key => {
            oktaIDPs.push({
                idp: oktaConfig.multipleIDP[key],
                text: `${key} login`
            });
        });
    } else if (oktaConfig.idp) {
        oktaIDPs.push({
            idp: oktaConfig.idp,
            text: `${clientName ?? ''} Login`
        });
    }
    return (
        <Box sx={loginStyles.container}>
            <Box sx={loginStyles.page}>
                <Box sx={loginStyles.loginButtonWrapper}>
                    <Card sx={loginStyles.card}>
                        {oktaIDPs.map(idpObj => (
                            <Button
                                sx={loginStyles.loginButton}
                                variant='contained'
                                color='primary'
                                key={idpObj.idp}
                                onClick={loginWithIDP(idpObj.idp)}
                                endIcon={<KeyboardArrowRight sx={loginStyles.endIcon} />}>
                                {idpObj.text}
                            </Button>
                        ))}
                        <Button
                            sx={loginStyles.loginGuestButton}
                            type='submit'
                            color='primary'
                            onClick={loginWithCustomEmail}>
                            {t('login.guest_email_login')}
                        </Button>
                    </Card>
                </Box>
                {isUnsupportedSafari && (
                    <Box sx={loginStyles.messageCardWrapper}>
                        <Card sx={loginStyles.messageCard}>
                            <Box sx={loginStyles.messageTitle}>{t('login.browser_performance_disclaimer_title')}</Box>
                            <Box sx={loginStyles.messageText}>{t('login.browser_performance_disclaimer_message')}</Box>
                        </Card>
                    </Box>
                )}
                <Box sx={loginStyles.messageCardWrapper}>
                    <Card sx={loginStyles.messageCard}>
                        <Box sx={loginStyles.messageTitle}>{t('login.cookie_consent_label')}</Box>
                        <Box sx={loginStyles.messageText}>{t('login.cookie_consent_message')}</Box>
                    </Card>
                </Box>
            </Box>
            <Box sx={loginStyles.footer}>
                <NarrowFooter />
            </Box>
        </Box>
    );
};
