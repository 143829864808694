import React from 'react';

export const NYSELogoSmall = () => (
    <svg width='52' height='25' viewBox='0 0 72 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M56.8462 4.52016H67.0869V14.776H71.6278V0H56.8462V4.52016Z' fill='#71C5E8' />
        <path
            d='M29.5249 12.9907H26.01L22.2101 19.1822L18.2962 12.9907H14.6104L20.4432 21.8981V27.9946H23.5781V21.9551L29.5249 12.9907Z'
            fill='white'
        />
        <path
            d='M37.752 19.2201L36.0041 18.8213C33.9332 18.3655 33.5152 17.9856 33.5152 17.093C33.5152 16.2384 34.4081 15.4217 36.0991 15.4217C37.619 15.4217 39.006 15.8775 40.203 16.9031L42.0269 14.4911C40.336 13.1046 38.17 12.6108 36.2321 12.6108C32.7172 12.6108 30.2473 14.681 30.2473 17.4159C30.2473 19.581 31.6912 20.9674 34.6551 21.5372L36.3841 21.8791C38.379 22.2779 39.12 23.0186 39.12 23.8732C39.12 25.0698 38.094 25.6205 36.4031 25.6585C34.7691 25.6965 33.1922 25.1077 31.7862 23.6643L29.9053 25.9434C31.3492 27.6147 33.9142 28.3554 36.3271 28.3554C39.994 28.3554 42.2739 26.5701 42.2739 23.6643C42.2549 21.1763 40.678 19.8849 37.752 19.2201Z'
            fill='white'
        />
        <path
            d='M9.93671 22.8857L2.71692 12.8008H0V27.8047H2.88792V17.7198L10.1267 27.8047H12.8246V12.8008H9.93671V22.8857Z'
            fill='white'
        />
        <path
            d='M47.3278 21.8221C47.8218 24.0822 49.5507 25.5825 51.8307 25.5825C53.4836 25.5825 54.8136 24.8229 55.7636 23.4934L57.9485 25.3356C56.4665 27.2729 54.4146 28.3744 51.8307 28.3744C47.0618 28.3744 43.9839 24.9558 43.9839 20.4926C43.9839 16.0294 47.0428 12.6108 51.4127 12.6108C54.6806 12.6108 59.1645 14.8899 58.4235 21.8031L47.3278 21.8221ZM55.4596 19.3911C55.1176 16.3143 53.1416 15.3837 51.7737 15.3837C49.4747 15.3837 47.8028 16.8271 47.2708 19.3911H55.4596Z'
            fill='white'
        />
    </svg>
);

export const NYSEMainLogo = () => (
    <svg width='182' height='80' viewBox='0 0 182 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M150.107 9.71945H172.11V31.7217H181.833V0H150.107V9.71945Z' fill='#71C5E8' />
        <path
            d='M91.492 27.8828H83.965L75.7942 41.1966L67.391 27.8828H59.4688L72.0123 47.0324V60.1017H78.7487V47.1179L91.492 27.8828Z'
            fill='white'
        />
        <path
            d='M109.137 41.2419L105.368 40.382C100.946 39.3917 100.009 38.597 100.009 36.6776C100.009 34.8234 101.924 33.1036 105.563 33.1036C108.787 33.0555 111.917 34.1858 114.366 36.2823L118.27 31.119C114.631 28.144 109.997 27.0845 105.828 27.0845C98.2848 27.0845 92.991 31.5183 92.991 37.4071C92.991 42.0365 96.1045 45.0155 102.454 46.2707L106.162 47.0002C110.462 47.86 112.047 49.4453 112.047 51.2995C112.047 53.8792 109.867 55.0732 106.227 55.1384C102.719 55.2036 99.3443 53.9485 96.3001 50.839L92.2656 55.7293C95.375 59.3033 100.868 60.8927 106.028 60.8927C113.901 60.8927 118.8 57.0538 118.8 50.835C118.8 45.4801 115.425 42.7008 109.137 41.2419Z'
            fill='white'
        />
        <path
            d='M49.3257 49.5305L33.8113 27.8828H28V60.1017H34.2066V38.4377L49.721 60.1017H55.5323V27.8828H49.3257V49.5305Z'
            fill='white'
        />
        <path
            d='M129.701 46.8323C130.744 51.6818 134.473 54.885 139.38 54.885C142.946 54.885 145.786 53.2345 147.832 50.4022L152.518 54.3674C149.352 58.5241 144.926 60.8878 139.38 60.8878C129.147 60.8878 122.545 53.5524 122.545 43.9837C122.545 34.415 129.094 27.0796 138.471 27.0796C145.493 27.0796 155.098 31.9699 153.509 46.8241L129.701 46.8323ZM147.139 41.616C146.422 35.0182 142.155 33.0294 139.253 33.0294C134.318 33.0294 130.744 36.1307 129.599 41.616H147.139Z'
            fill='white'
        />
        <path d='M60.1324 73.208H58.7754V79.8343H60.1324V73.208Z' fill='white' />
        <path
            d='M64.9787 79.9974C65.5041 80.0075 66.0242 79.8906 66.4946 79.6564C66.9651 79.4223 67.372 79.0778 67.6806 78.6525L66.7148 77.8375C66.5242 78.1239 66.2653 78.3584 65.9616 78.5199C65.6578 78.6814 65.3186 78.7648 64.9746 78.7626C63.7806 78.7626 62.937 77.8375 62.937 76.5212C62.937 75.2049 63.7113 74.2676 64.9746 74.2676C65.3181 74.2682 65.6562 74.3529 65.9595 74.5141C66.2628 74.6753 66.5221 74.9083 66.7148 75.1926L67.6398 74.2839C67.184 73.7357 66.5699 73.342 65.8815 73.1565C65.1932 72.9711 64.4644 73.0031 63.7949 73.2481C63.1254 73.4931 62.5482 73.9392 62.1421 74.5252C61.7361 75.1111 61.5212 75.8083 61.527 76.5212C61.5057 76.9812 61.5803 77.4407 61.7459 77.8704C61.9116 78.3001 62.1646 78.6908 62.4891 79.0176C62.8136 79.3444 63.2025 79.6002 63.631 79.7689C64.0596 79.9375 64.5185 80.0153 64.9787 79.9974V79.9974Z'
            fill='white'
        />
        <path
            d='M70.0528 77.1079C70.1325 77.5756 70.3767 77.9994 70.7414 78.3028C71.1061 78.6062 71.5672 78.7692 72.0415 78.7625C72.3851 78.7651 72.7238 78.6819 73.027 78.5203C73.3302 78.3587 73.5882 78.124 73.7776 77.8374L74.7434 78.6525C74.4348 79.0778 74.0279 79.4222 73.5575 79.6563C73.087 79.8905 72.5669 80.0075 72.0415 79.9973C71.5805 80.0164 71.1205 79.9394 70.6908 79.7714C70.2611 79.6033 69.871 79.3477 69.5453 79.0208C69.2195 78.6939 68.9653 78.303 68.7988 77.8726C68.6322 77.4423 68.5569 76.9821 68.5776 76.5211C68.5776 74.5528 69.9265 73.0449 71.8378 73.0449C73.2804 73.0449 75.2569 74.0515 74.9309 77.1202L70.0528 77.1079ZM73.639 76.0321C73.4882 74.6791 72.6121 74.2675 72.0089 74.2675C70.9942 74.2675 70.2607 74.9073 70.0243 76.0321H73.639Z'
            fill='white'
        />
        <path
            d='M43.33 73.208L40.6934 79.8343H42.0504L42.6169 78.3347H45.4247L45.9912 79.8343H47.4134L44.7931 73.208H43.33ZM43.0203 77.2832L44.0188 74.6262L45.0213 77.2832H43.0203Z'
            fill='white'
        />
        <path
            d='M52.7932 77.6622L49.6022 73.208H48.4082V79.8343H49.6838V75.3801L52.8747 79.8343H54.0687V73.208H52.7932V77.6622Z'
            fill='white'
        />
        <path
            d='M90.3757 76.4519L92.556 73.208H91.0196L89.5606 75.5024L88.0732 73.208H86.3779L88.5785 76.4804L86.3086 79.8343H87.8572L89.4058 77.4666L90.9666 79.8343H92.6497L90.3757 76.4519Z'
            fill='white'
        />
        <path
            d='M125.835 74.267C126.182 74.2801 126.522 74.3681 126.831 74.525C127.141 74.6819 127.413 74.9039 127.628 75.1758L128.549 74.2018C128.206 73.8188 127.783 73.5165 127.31 73.3164C126.836 73.1163 126.324 73.0234 125.811 73.0444C125.356 73.041 124.906 73.129 124.486 73.3033C124.066 73.4776 123.686 73.7346 123.368 74.059C123.049 74.3834 122.8 74.7685 122.633 75.1914C122.467 75.6143 122.388 76.0663 122.4 76.5206C122.4 78.4889 123.744 79.9968 125.945 79.9968C126.423 80.0166 126.899 79.9402 127.346 79.7722C127.794 79.6041 128.203 79.3479 128.549 79.0187V76.0316H125.456V77.1074H127.274V78.44C126.867 78.6902 126.394 78.8123 125.917 78.7905C124.519 78.7905 123.797 77.7839 123.797 76.5206C123.797 75.2573 124.584 74.267 125.835 74.267Z'
            fill='white'
        />
        <path
            d='M104.9 76.0322H101.901V73.208H100.568V79.8343H101.901V77.108H104.9V79.8343H106.229V73.208H104.9V76.0322Z'
            fill='white'
        />
        <path
            d='M119.758 77.6622L116.567 73.208H115.373V79.8343H116.649V75.3801L119.839 79.8343H121.034V73.208H119.758V77.6622Z'
            fill='white'
        />
        <path
            d='M80.7384 77.1079C80.8188 77.5752 81.0633 77.9985 81.4278 78.3018C81.7923 78.605 82.253 78.7684 82.7271 78.7625C83.0712 78.7651 83.4105 78.6818 83.7143 78.5203C84.0181 78.3588 84.2769 78.1241 84.4672 77.8374L85.429 78.6525C85.1204 79.0778 84.7135 79.4222 84.243 79.6563C83.7726 79.8905 83.2525 80.0075 82.7271 79.9973C82.2662 80.0164 81.8064 79.9395 81.3769 79.7714C80.9474 79.6032 80.5575 79.3476 80.2321 79.0206C79.9067 78.6937 79.6529 78.3027 79.4868 77.8723C79.3207 77.442 79.2459 76.9819 79.2672 76.5211C79.2672 74.5528 80.6121 73.0449 82.5274 73.0449C83.9701 73.0449 85.9465 74.0515 85.6165 77.1202L80.7384 77.1079ZM84.3246 76.0321C84.1779 74.6791 83.3017 74.2675 82.6945 74.2675C81.6798 74.2675 80.9462 74.9073 80.7099 76.0321H84.3246Z'
            fill='white'
        />
        <path
            d='M96.6556 79.9974C97.181 80.0076 97.701 79.8906 98.1715 79.6565C98.6419 79.4223 99.0488 79.0779 99.3574 78.6526L98.3916 77.8375C98.2022 78.1241 97.9442 78.3589 97.641 78.5204C97.3378 78.682 96.9991 78.7652 96.6556 78.7626C95.4615 78.7626 94.6179 77.8375 94.6179 76.5212C94.6179 75.2049 95.3922 74.2676 96.6556 74.2676C97.001 74.267 97.3413 74.351 97.6468 74.5123C97.9522 74.6735 98.2136 74.9071 98.4079 75.1927L99.333 74.2839C98.8761 73.7381 98.262 73.3465 97.5744 73.1625C96.8868 72.9785 96.1592 73.0111 95.4908 73.2557C94.8224 73.5003 94.2457 73.9452 93.8393 74.5296C93.433 75.114 93.2167 75.8095 93.2201 76.5212C93.1996 76.9797 93.2742 77.4375 93.439 77.8658C93.6039 78.2941 93.8555 78.6837 94.1781 79.0101C94.5007 79.3365 94.8873 79.5927 95.3137 79.7626C95.74 79.9326 96.1969 80.0125 96.6556 79.9974V79.9974Z'
            fill='white'
        />
        <path
            d='M110.076 73.208L107.443 79.8343H108.8L109.367 78.3347H112.171L112.737 79.8343H114.163L111.543 73.208H110.076ZM109.77 77.2832L110.769 74.6262L111.767 77.2832H109.77Z'
            fill='white'
        />
        <path
            d='M131.389 77.1079C131.468 77.5756 131.713 77.9994 132.077 78.3028C132.442 78.6062 132.903 78.7692 133.377 78.7625C133.721 78.7651 134.06 78.6819 134.363 78.5203C134.666 78.3587 134.924 78.124 135.114 77.8374L136.079 78.6525C135.771 79.0778 135.364 79.4222 134.893 79.6563C134.423 79.8905 133.903 80.0075 133.377 79.9973C132.916 80.0164 132.456 79.9394 132.027 79.7714C131.597 79.6033 131.207 79.3477 130.881 79.0208C130.555 78.6939 130.301 78.303 130.135 77.8726C129.968 77.4423 129.893 76.9821 129.914 76.5211C129.914 74.5528 131.262 73.0449 133.174 73.0449C134.616 73.0449 136.593 74.0515 136.267 77.1202L131.389 77.1079ZM134.975 76.0321C134.824 74.6791 133.948 74.2675 133.345 74.2675C132.33 74.2675 131.597 74.9073 131.36 76.0321H134.975Z'
            fill='white'
        />
    </svg>
);
