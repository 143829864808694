import { hexToRgb } from '../../../Utils/styles/global.style';

const generateHoverBgColor = theme => {
    const loginButtonRgb = hexToRgb(theme.palette.loginButton.main) || { r: 0, g: 0, b: 0 };
    return `rgba(${loginButtonRgb.r}, ${loginButtonRgb.g}, ${loginButtonRgb.b}, 0.9)`;
};

export const loginStyles = {
    container: {
        display: { xs: 'flex', md: 'unset' },
        flexDirection: { xs: 'column', md: 'unset' },
        width: '100%',
        height: '100%'
    },
    page: {
        background: theme => theme.palette.loginGradient.main,
        height: 'inherit',
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    card: {
        maxWidth: 344,
        margin: 'auto',
        bgcolor: 'white',
        p: '32px 32px 20px',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box'
    },
    messageCardWrapper: {
        p: { xs: '5px', md: 'unset' }
    },
    messageCard: {
        maxWidth: 880,
        m: 'auto',
        bgcolor: { xs: 'black', md: theme => theme.palette.loginButton.main },
        color: 'white',
        p: '14px 24px',
        alignItems: 'left',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        position: 'absolute',
        bottom: { xs: 0, md: '4%' },
        left: { xs: 0, md: '50%' },
        transform: { md: 'translate(-50%, -4%)' },
        borderRadius: { xs: 0, md: 0.5 }
    },
    messageTitle: {
        fontSize: 12,
        lineHeight: '24px',
        fontWeight: 'bold'
    },
    messageText: {
        fontSize: 12,
        lineHeight: '24px'
    },
    loginButtonWrapper: {
        padding: '0px 20px 88px 20px'
    },
    loginButton: {
        textTransform: 'none',
        bgcolor: theme => theme.palette.loginButton.main,
        '&:hover': {
            bgcolor: generateHoverBgColor
        },
        borderRadius: 5,
        height: 72,
        width: '100%',
        fontSize: 14,
        lineHeight: '24px',
        justifyContent: 'space-between',
        p: '14px 24px',
        alignItems: 'center',
        fontFamily: 'ClientFontMedium',
        mb: 1
    },
    loginGuestButton: {
        textTransform: 'none',
        textDecoration: 'underline',
        fontSize: 15,
        letterSpacing: 0,
        color: theme => theme.palette.loginButton.main,
        fontFamily: 'ClientFontRegular'
    },
    footer: {
        backgroundColor: 'black',
        display: { xs: 'flex', md: 'none' },
        height: { xs: 'auto', md: 0 },
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        position: 'relative'
    },
    endIcon: {
        height: 32,
        weight: 32
    }
};
