import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Security, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { ThemeProvider } from '@mui/material/styles';
import { isTablet, isMobile } from 'react-device-detect';
import Logout from './Auth/Logout';
import { LoginCallbackWithError } from './Auth/LoginCallbackWithError';
import { LoginPage } from './Auth/Login';
import { CALLBACK_PATH, oktaConfig } from '../oktaConfig';
import Header, { SmallerHeader } from './Header/Header';
import muiTheme from '../Utils/styles/muiTheme';
import { Box } from '@mui/material';
import { rootStyles } from './Root.style';
import { LoginConfirmation } from './Auth/LoginConfirmation';

const authClient = new OktaAuth(oktaConfig);

const Root = () => {
    const history = useHistory();
    const showLoginPage = async () => {
        history.push('/login');
    };
    const location = useLocation();
    const momentPath = '/celebration';
    const webGLPath = '/';
    const isMomentPath = location.pathname === momentPath;
    const showHeader = !isMomentPath;

    let HeaderComponent = null;
    if (showHeader) {
        HeaderComponent = isMobile && !isTablet ? <SmallerHeader /> : <Header />;
    }

    // In the new version of Okta, in order to support token auto renew, we need to have the service running in the
    // background.
    // https://github.com/okta/okta-auth-js#running-as-a-service
    useEffect(() => {
        authClient.start();

        return () => {
            authClient.stop();
        };
    }, []);

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    return (
        <ThemeProvider theme={muiTheme}>
            <div>
                <Security oktaAuth={authClient} onAuthRequired={showLoginPage} restoreOriginalUri={restoreOriginalUri}>
                    {HeaderComponent}
                    <Box sx={rootStyles.container(showHeader && (!isMobile || isTablet))}>
                        <Switch>
                            <Route path={CALLBACK_PATH} component={LoginCallbackWithError} />
                            <Route path='/login' component={LoginPage} />
                            <SecureRoute path={webGLPath} exact component={LoginConfirmation} />
                            <Route path='/logout' component={Logout} />
                            <Redirect path='*' to={webGLPath} />
                        </Switch>
                    </Box>
                </Security>
            </div>
        </ThemeProvider>
    );
};

export default Root;
