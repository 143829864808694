export const hexToRgb = hex => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16)
          }
        : null;
};

export const momentsSharedStyles = {
    container: top => theme => ({
        bgcolor: theme => theme.palette.momentsGradient.primary,
        display: 'flow-root',
        position: 'absolute',
        width: '50%',
        height: '100%',
        top: top,
        pointerEvents: 'none',
        [theme.breakpoints.down('xl')]: {
            height: 'auto',
            pb: '30px',
            width: '100%',
            position: 'inherit',
            mt: '-18px'
        }
    }),
    title: theme => ({
        width: '60%',
        margin: '0 auto',
        [theme.breakpoints.down('xl')]: {
            width: '100%',
            ml: '20px'
        }
    }),
    bottomContainer: theme => ({
        display: 'grid',
        gridTemplateColumns: '100%',
        width: '60%',
        margin: '0 auto',
        marginTop: '30vh',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xl')]: {
            p: '20px',
            display: 'inherit',
            width: 'auto',
            mt: '100px'
        }
    }),
    sectionImg: {
        filter: 'drop-shadow(0 0 0.3rem rgba(255, 255, 255, 0.3))'
    },
    headline: theme => ({
        height: 103,
        fontFamily: 'ClientFontBold',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 80,
        lineHeight: '103px',
        pt: '16px',
        color: '#FFFFFF',
        [theme.breakpoints.down('lg')]: {
            fontSize: 64,
            pt: 0
        },
        [theme.breakpoints.down('md')]: {
            height: 56,
            fontSize: '48px !important',
            lineHeight: '52px'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '40px !important',
            lineHeight: '36px'
        }
    }),
    body: theme => ({
        fontFamily: 'ClientFontRegular',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: '26px',
        letterSpacing: '-0.03em',
        color: '#FFFFFF',
        [theme.breakpoints.down('md')]: {
            fontSize: 16,
            lineHeight: '24px',
            width: '100%'
        }
    })
};
