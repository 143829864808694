export const loginCallbackWithErrorStyles = {
    assignmentInd: { height: 72, width: 72 },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme => theme.palette.loginGradient.main,
        height: '100%',
        width: '100%',
        fontFamily: "'Open Sans', sans-serif"
    },
    errorMessage: {
        display: 'flex',
        fontSize: 18,
        fontWeight: 400,
        color: 'white',
        textAlign: 'center',
        maxWidth: 630,
        p: '10px',
        mb: '16px',
        background: 'rgba(0, 0, 0, 0.24)',
        border: '1px solid rgba(255, 255, 255, 0.24)',
        borderRadius: '4px'
    },
    retryButton: {
        color: 'white',
        textTransform: 'none',
        fontFamily: 'InterRegular',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px'
    }
};
