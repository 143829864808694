import { StorageKeys } from './constants';
import { OKTA_ISSUER, OKTA_MULTIPLE_IDP } from './environment';

export const CALLBACK_PATH = '/implicit/callback';
const REDIRECT_URI = window.location.origin + CALLBACK_PATH;

const params = new URLSearchParams(window.location.search);
const clientId = params.get(StorageKeys.clientId);
const idp = params.get(StorageKeys.idp);
const ipoDate = params.get(StorageKeys.ipoDate);
const clientName = params.get(StorageKeys.clientName);

clientId && localStorage.setItem(StorageKeys.clientId, clientId);
idp && localStorage.setItem(StorageKeys.idp, idp);
ipoDate && localStorage.setItem(StorageKeys.ipoDate, ipoDate);
clientName && localStorage.setItem(StorageKeys.clientName, clientName);

export const oktaConfig = {
    clientId: localStorage.getItem(StorageKeys.clientId),
    issuer: OKTA_ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    idp: localStorage.getItem(StorageKeys.idp),
    multipleIDP: OKTA_MULTIPLE_IDP ? JSON.parse(OKTA_MULTIPLE_IDP) : undefined
};
