import { headerLg, headerSm } from '../../Utils/constants/styles';

export const headerStyles = {
    clientLogo: theme => ({
        m: 0,
        p: 0,
        border: 0,
        height: 32,
        width: 'auto',
        // LP-4654: reduced logo size on mobile.
        '@media (orientation: portrait)': {
            [theme.breakpoints.down('md')]: {
                maxHeight: 20
            }
        }
    }),
    copyright: {
        color: '#ffffff',
        fontFamily: 'InterRegular',
        fontSize: 12,
        letterSpacing: 0
    },
    copyrightNarrow: {
        color: '#ffffff',
        fontFamily: 'InterRegular',
        fontSize: 12,
        letterSpacing: 0,
        mt: '8px',
        textAlign: 'center'
    },
    header: {
        width: '100%',
        p: '0px 5%',
        height: headerLg,
        background: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: 'white',
        fontFamily: "'Open Sans', sans-serif",
        boxSizing: 'border-box',
        maxWidth: 'calc(100vh * 16 / 9)',
        m: '0 auto',
        '-webkit-user-select': 'none' /* Safari */,
        '-khtml-user-select': 'none' /* Konqueror HTML */,
        '-moz-user-select': 'none' /* Firefox */,
        '-ms-user-select': 'none' /* Internet Explorer/Edge */,
        'user-select': 'none' /* supported by Chrome and Opera */
    },
    headerRight: {
        flexShrink: 0,
        opacity: 0.6
    },
    headerMiddle: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        minWidth: '420px',
        m: '0 auto',
        mt: '4.5vh'
    },
    headerCenter: {
        width: '100%',
        p: '20px 5%',
        bgcolor: 'black',
        alignItems: 'center',
        color: 'white',
        fontFamily: "Open Sans', sans-'serif",
        boxSizing: 'border-box',
        justifyContent: 'center',
        verticalAlign: 'baseline',
        display: 'grid',
        gridAutoFlow: 'row'
    },
    headerLeft: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 1
    },
    legal: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    legalCenter: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridColumnGap: 10,
        justifyContent: 'center',
        mt: '16px'
    },
    link: {
        fontFamily: 'InterRegular',
        color: 'white',
        fontSize: 12,
        letterSpacing: 0,
        textDecoration: 'underline'
    },
    listingDay: {
        fontFamily: 'IBMPlexSansRegular',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 20,
        letterSpacing: '-0.4px',
        color: '#ffffff'
    },
    mobileHeader: theme => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: headerSm,
        '@media (orientation: landscape)': {
            [theme.breakpoints.down('xl')]: {
                display: 'none'
            }
        }
    }),
    momentsVerticalBar: {
        height: 20,
        width: '1px',
        bgcolor: '#FFFFFF',
        margin: '0px 20px'
    },
    NYSEVerticalBar: {
        height: 32,
        width: '1px',
        bgcolor: '#b8b8b8',
        margin: '0px 32px'
    }
};
